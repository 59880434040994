.header {
  background-color: #FBFBFB !important;
  /* border-bottom: 1px solid #ddd; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.mosouq-logo{
  color: #404EED;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 40px;
  margin-right: -1rem;
  text-decoration: none !important;
}

.header-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 88px;
  justify-content: space-between;
 
  
}

.logo img {
  height: 40px;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.nav-links a:hover {
  color: #404EED;
}

.business-button-header {
  width: 191px;
  height: 53px;
  padding: 17px 50px;
  border-radius: 33px;
  background: #404EED;
  font-family: "Figtree", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.2px;
  color: #FFFFFF;
  border: none;
}

.business-button-header-demo {
  width: 200px;
  height: 53px;
  padding: 17px 50px;
  border-radius: 33px;
  background: #404EED;
  font-family: "Figtree", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.2px;
  color: #FFFFFF;
  border: none;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

@media (max-width: 1024px) {
 

  .nav-links {
    margin-left: auto;
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 88px;
    right: 0;
    background: #FBFBFB;
    flex-direction: column;
    width: 100%;
    text-align: center;
    gap: 0;
    padding: 10px 0;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links a {
    padding: 10px 0;
  }

  .business-button-header {
    width: 100%;
    margin-top: 10px;
  }

  .menu-icon {
    display: block;
  }
}

@media (max-width: 480px) {
  .header-container {
    padding: 0 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .menu-icon {
    display: block;
    margin-left: 5rem;
  }

  .nav-links a {
    font-size: 14px;
  }

  .business-button-header {
    font-size: 12px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {

  .menu-icon {
    display: block;
    margin-left: 30rem;
  }

}