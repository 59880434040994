.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7rem;
    /* background-image: url('/public/about_right.png');
    background-position:center; */
  }
  
  .about-head {
    font-weight: 700;
    font-size: 62px;
  }
  
  .about-subhead {
    color: #686868;
    font-size: 24px;
    font-weight: 400;
  }

  
  .left-col-head {
    font-weight: 700;
    font-size: 32px;
    color: #000000;
  }
  
  .left-col-body {
    font-weight: 400;
    font-size: 24px;
    color: #2f2f2f;
  }

  
  .right-col-head {
    font-weight: 600;
    font-size: 24px;
    color: #686868;
    text-align: center;
  }
  
  .right-col-subhead {
    font-weight: 600;
    font-size: 32px;
    color: #161616;
    text-align: center;
  }
  
  .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .right-col-body {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #686868;
  }
  
  .home-review-container {
    margin-bottom: 7rem;
    margin-top: 6rem;
  }
  
  /* Media Query for Mobile and Tablets */
  @media (max-width: 768px) {

    .about-subhead {
      color: #686868;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
    }

    
    .about-head {
      text-align: center;
    }
  
    .left-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .left-col-head,
    .left-col-body {
      text-align: center;
    }
  }
  