.contact-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
}

.contact-form label {
    font-weight: bold;
}

.contact-form input,
.contact-form textarea {
    margin-bottom: 15px;
}

.contact-form button.submit-button {
    background-color: lightblue;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.contact-form button.submit-button:hover {
    background-color: #0099cc;
}
