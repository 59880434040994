.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #e4e5e8 !important;
  border-radius: 10px !important;
  padding: 12px;
  background-color: #ffffff;
  position: relative;
  margin-top: 2rem;
  box-shadow: 0px 12px 40px 0px #002c6d0a !important;
  width: 100%;
  /* max-width: 546px; */
  height: 80px;
  gap: 12px;
}

.search-icon {
  position: absolute;
  left: 15px;
  color: #0066ff;
  background-color: #ffffff;
  height: 22px;
  width: 22px;
}

.search-input {
  border: none;
  outline: none;
  padding: 10px 15px;
  flex: 1;
  background-color: transparent;
  margin-left: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}

.search-bar .search-input {
  border: none;
  outline: none;
  box-shadow: none;
}

/* .search-input:focus {
    border: none;
    outline: none;
    box-shadow: none;
  } */

.search-button {
  width: 100px;
  height: 60px;
  padding: 16px 32px;
  gap: 12px;
  border-radius: 8px !important;
  background: #404eed !important;
  color: white;
  font-size: 16px;
  font-weight: 600;
  z-index: 2;
}

/* .custom-div {
  background-color: #FAFAFA !important;
  width: 100%;
}

.custom-div-container {
  position: relative;
  border: 1px solid #EAEAEA;
  border-radius: 12px !important;
  padding: 10px;
}  */

.card-div-cont{
  display: flex;
  align-items: center;
  border: 1px solid #e4e5e8 !important;
  border-radius: 10px !important;
  padding: 12px;
  background-color: #ffffff;
  position: relative;
  margin-top: 2rem;
  box-shadow: 0px 12px 40px 0px #002c6d0a !important;
  width: 100%;
  /* max-width: 546px; */
  height: 80px;
  gap: 12px;
}

.custom-img {
  width: 100%;
  height: 50px;
}

.custom-text {
  font-size: 16px;
  font-weight: 500;
}

