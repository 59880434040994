#categoryDetails{
  margin-left: 4rem;
  margin-right: 4rem;
}

.descp-button {
  width: 150px;
  height: 58.75px;
  top: 393.62px;
  left: 64.12px;
  border-radius: 10px;
  border: 0.99px;
  border: 1px solid #ebebeb;
  text-align: center;
  justify-content: center;
  display: grid;
  place-items: center;
}

/* Business Update Section CSS */

.business-head {
  font-family: "Figtree";
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  margin-top: 3rem;
}

.card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
  padding: 1rem;
  width: 514px;
  margin-left: 5rem;
}

.card-image {
  width: 100%;
  object-fit: cover;
  margin-top: 1rem;
}

.card-text {
  font-family: "Figtree";
  font-size: 14.11px;
  font-weight: 400;
  line-height: 16.93px;
  text-align: left;
  color: #000000b2;
}

.card-title {
  font-family: "Figtree";
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black; /* Change this to the color you want */
  background-image: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: -2rem;
}

.carousel-control-prev-icon:after,
.carousel-control-next-icon:after {
  content: "";
  border: solid black;
  border-width: 0 4px 4px 0;
  display: inline-block;
}

.carousel-control-prev-icon:after {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.carousel-control-next-icon:after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Free Estimation CSS */
.esti-head {
  font-family: "Figtree";
  font-size: 21.16px;
  font-weight: 700;
  line-height: 25.39px;
  text-align: center;
}

.esti-button {
  width: 100%;
  height: 56.7px;
  top: 773.35px;
  left: 916.29px;
  border: 1px solid #404eed;
  font-family: "Figtree";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color:#404EED;
  text-align: center;
  display: grid;
  place-items: center;
  margin-top: 2rem;
}
