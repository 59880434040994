.filter-card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .filter-header h3 {
    margin: 0;
  }
  
  .filter-header button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .filter-section {
    margin-top: 16px;
  }
  
  .filter-section h4 {
    margin-bottom: 8px;
    margin-top: 0;
  }
  
  .filter-section label {
    display: block;
    margin-bottom: 8px;
  }
  
  .filter-section input[type="number"] {
    display: flex;
    flex-direction: row;
    width: 80px;
    margin-right: 4px;
    border: 0.94px solid #F1F1F1;
    width: 125px;
    height: 48px;
  }
  
  .filter-section input[type="checkbox"] {
    margin-right: 8px;
  }
  

  .emirate-row {
    display: flex;
    align-items: center;
    border: 1px solid #F1F1F1; /* Black border around each row */
    padding: 5px;
    width: 100%;
    margin: 5px 0;
    color: #00000099;
    font-size: 16px;
  }
  